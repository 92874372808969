import * as React from "react";
import Modal from "react-modal";
import logomail from "../assets/img/mail.png";
import warning from "../assets/img/warning.png";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { PreRegisterAPI } from "../services/apigateway";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

// css-layouts
import "../styles/PreRegister.css";
import "../styles/thirdparty/salesforce/styles/salesforce-lightning-design-system.css";
import symbols from "../styles/thirdparty/salesforce/icons/utility-sprite/svg/symbols.svg";
import { Typography } from "@mui/material";

export default function PreRegisterScreen() {
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [isOffline, setIsOffline] = useState(false);
  const [email, setEmail] = useState("");
  const [requiredFields, setRequiredFields] = useState({});
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [picklistMobileCountry, setPicklistMobileCountry] = useState([]);
  const [picklistLanguage, setPicklistLanguage] = useState([]);
  const [picklistCountryData, setPicklistCountryData] = useState([]);
  const [picklistTitle, setPicklistTitle] = useState([]);

  const showAlertMessage = function (errors) {
    if (errors.message === "Failed to fetch") {
      setIsOffline(true);
    } else {
      setAlertMessage(errors.message);
    }
  };

  const initializeComponent = React.useCallback(async function () {
    setIsLoading(true);
    setIsOffline(false);
    setAlertMessage("");

    const pMobileCountryCode = PreRegisterAPI.getPicklist({
      objectApiName: "Lead",
      fieldApiName: "MobileCountryCode__c",
    });
    const pSpecifyLanguageofCompanyName = PreRegisterAPI.getPicklist({
      objectApiName: "Lead",
      fieldApiName: "SpecifyLanguageofCompanyName__c",
    });
    const pCountry = PreRegisterAPI.getCountry();
    const pSalutation = PreRegisterAPI.getPicklist({
      objectApiName: "Lead",
      fieldApiName: "Salutation",
    });

    Promise.all([
      pMobileCountryCode,
      pSpecifyLanguageofCompanyName,
      pCountry,
      pSalutation,
    ])
      .then(
        ([
          pickListMobileCounty,
          picklistLanguage,
          pickListCounty,
          picklistTitle,
        ]) => {
          setPicklistMobileCountry(pickListMobileCounty);
          setPicklistLanguage(picklistLanguage);
          setPicklistCountryData(pickListCounty);
          setPicklistTitle(picklistTitle);
        }
      )
      .catch((errors) => {
        showAlertMessage(errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    initializeComponent();
  }, [initializeComponent]);

  const customStyles = {
    content: {
      width: "400px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const customStylesWarning = {
    content: {
      width: "500px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    navigate("/");
  }

  const [modalWarningIsOpen, setIsWarningOpen] = React.useState(false);
  function openModalWarning() {
    setEmail(document.getElementById("Email").value);
    setIsWarningOpen(true);
  }
  function closeModalWarning() {
    setIsWarningOpen(false);
  }

  const setErrorMessage = function (message) {
    Swal.fire({
      title: message,
      icon: "error",
      showConfirmButton: true,
    });
  };

  const [isCheckedCustomer, setIsCheckedCustomer] = useState(false);
  const ChangeCheckedCustomer = (event) => {
    if (event.target.checked) {
      console.log("Checkbox is checked");
    } else {
      console.log("Checkbox is NOT checked");
      const checkboxes = document.querySelectorAll('input[name="Customer"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      setIsSubLubeBase(false);
      const element = document.getElementById("Products");
      element.value = "";

      setIsSubLabix(false);
      const element1 = document.getElementById("InterestedLABIXProducts");
      element1.value = "";

      setIsSubOther(false);
      const element2 = document.getElementById("InterestedOtherProducts");
      element2.value = "";

      handleChangeSelectedProduct(event);
    }
    setIsCheckedCustomer((current) => !current);
  };

  const [isSubLubeBase, setIsSubLubeBase] = useState(false);
  const handleChangeLubeBase = (event) => {
    const element = document.getElementById("Products");
    if (event.target.checked) {
      // console.log('Checkbox is checked');
      if (!element.value) {
        setRequiredFields((requiredFields) => {
          return {
            ...requiredFields,
            isBlankLubeBaseProduct: true,
          };
        });
      }
    } else {
      // console.log('Checkbox is NOT checked');
      if (element) {
        element.disabled = true;
        element.value = ""; // Remove the value
      }
      setRequiredFields((requiredFields) => {
        return {
          ...requiredFields,
          isBlankLubeBaseProduct: false,
        };
      });
    }
    setIsSubLubeBase((current) => !current);
    handleChangeSelectedProduct(event);
  };

  const [isSubLabix, setIsSubLabix] = useState(false);
  const handleChangeLABIX = (event) => {
    const element = document.getElementById("InterestedLABIXProducts");
    if (event.target.checked) {
      // console.log('Checkbox is checked');
      if (!element.value) {
        setRequiredFields((requiredFields) => {
          return {
            ...requiredFields,
            isBlankLABIXProduct: true,
          };
        });
      }
    } else {
      // console.log('Checkbox is NOT checked');
      if (element) {
        element.disabled = true;
        element.value = ""; // Remove the value
      }
      setRequiredFields((requiredFields) => {
        return {
          ...requiredFields,
          isBlankLABIXProduct: false,
        };
      });
    }
    setIsSubLabix((current) => !current);
    handleChangeSelectedProduct(event);
  };

  const [isSubOther, setIsSubOther] = useState(false);
  const handleChangeOtherCustomer = (event) => {
    const element = document.getElementById("InterestedOtherProducts");
    if (event.target.checked) {
      // console.log('Checkbox is checked');
      if (!element.value) {
        setRequiredFields((requiredFields) => {
          return {
            ...requiredFields,
            isBlankOtherProductCustomer: true,
          };
        });
      }
    } else {
      // console.log('Checkbox is NOT checked');
      if (element) {
        element.disabled = true;
        element.value = ""; // Remove the value
      }
      setRequiredFields((requiredFields) => {
        return {
          ...requiredFields,
          isBlankOtherProductCustomer: false,
        };
      });
    }
    setIsSubOther((current) => !current);
    handleChangeSelectedProduct(event);
  };

  const [isCheckedSupplier, setIsCheckedSupplier] = useState(false);
  const ChangeCheckedSupplier = (event) => {
    if (event.target.checked) {
      console.log("Checkbox is checked");
    } else {
      console.log("Checkbox is NOT checked");
      const checkboxes = document.querySelectorAll('input[name="Supplier"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      setIsSubPetroleum(false);
      const element = document.getElementById("InterestedPetroleumProducts");
      element.value = "";

      setIsSubOtherSupplier(false);
      const element1 = document.getElementById(
        "InterestedOtherProductsSupplier"
      );
      element1.value = "";

      handleChangeSelectedProduct(event);
    }
    setIsCheckedSupplier((current) => !current);
  };

  const [isSubPetroleum, setIsSubPetroleum] = useState(false);
  const handleChangePetroleum = (event) => {
    const element = document.getElementById("InterestedPetroleumProducts");
    if (event.target.checked) {
      // console.log('Checkbox is checked');
      if (!element.value) {
        setRequiredFields((requiredFields) => {
          return {
            ...requiredFields,
            isBlankPetroleumAndComponent: true,
          };
        });
      }
    } else {
      // console.log('Checkbox is NOT checked');
      if (element) {
        element.disabled = true;
        element.value = ""; // Remove the value
      }
      setRequiredFields((requiredFields) => {
        return {
          ...requiredFields,
          isBlankPetroleumAndComponent: false,
        };
      });
    }
    setIsSubPetroleum((current) => !current);
    handleChangeSelectedProduct(event);
  };

  const [isSubOtherSupplier, setIsSubOtherSupplier] = useState(false);
  const handleChangeOtherSupplier = (event) => {
    const element = document.getElementById("InterestedOtherProductsSupplier");
    if (event.target.checked) {
      // console.log('Checkbox is checked');
      if (!element.value) {
        setRequiredFields((requiredFields) => {
          return {
            ...requiredFields,
            isBlankOtherProductSupplier: true,
          };
        });
      }
    } else {
      // console.log('Checkbox is NOT checked');
      if (element) {
        element.disabled = true;
        element.value = ""; // Remove the value
      }
      setRequiredFields((requiredFields) => {
        return {
          ...requiredFields,
          isBlankOtherProductSupplier: false,
        };
      });
    }
    setIsSubOtherSupplier((current) => !current);
    handleChangeSelectedProduct(event);
  };

  const handleChangeSelectedProduct = (e) => {
    const customerCheckboxes = document.querySelectorAll(
      'input[name="Customer"]:checked'
    );
    const supplierCheckboxes = document.querySelectorAll(
      'input[name="Supplier"]:checked'
    );

    if (customerCheckboxes.length === 0 && supplierCheckboxes.length === 0) {
      setRequiredFields((requiredFields) => {
        return {
          ...requiredFields,
          isNotSelectedProduct: true,
        };
      });
    } else {
      setRequiredFields((requiredFields) => {
        return {
          ...requiredFields,
          isNotSelectedProduct: false,
        };
      });
    }
  };

  const validateCheckboxes = function (callback) {
    const customerCheckboxes = document.querySelectorAll(
      'input[name="Customer"]:checked'
    );
    const supplierCheckboxes = document.querySelectorAll(
      'input[name="Supplier"]:checked'
    );

    if (supplierCheckboxes.length === 0 && customerCheckboxes.length === 0) {
      callback(false); // Callback with error status
    } else {
      callback(true); // Callback with success status
    }
  };

  const handleSubmit = function (e) {
    e.preventDefault(); // Prevent the default form submission
    closeModalWarning();
    setIsLoading(true);
    try {
      // if (isRecaptchaVerified) {
      validateCheckboxes((isValid) => {
        if (isValid) {
          if (window.timer) {
            clearTimeout(window.timer);
            delete window.timer;
          }
          window.timer = setTimeout(() => {
            PreRegisterAPI.preRegister()
              .then((response) => {
                // Check the response status
                if (response.status === "success") {
                  // Handle a successful response
                  // Perform actions for a successful registration
                  openModal();
                } else if (response.error_description) {
                  setErrorMessage(response.error_description);
                } else {
                  // Handle other status codes
                  setErrorMessage("An error occurred<br/>Please try again");
                }
              })
              .catch((error) => {
                // Handle any errors from the API call
                setErrorMessage("An error occurred<br/>Please try again");
              })
              .finally(() => {
                setIsLoading(false);
              });
          }, 1000);
        } else {
          setErrorMessage("Please Select <br/>Product Type of Interested");
          setIsLoading(false);
        }
      });
      // } else {
      //     setErrorMessage('Please complete<br/>the reCAPTCHA verification');
      // }
    } catch (error) {
      // Handle any other errors that might occur
      setErrorMessage("An error occurred<br/>Please try again");
    }
  };

  const handleRecaptchaVerify = (response) => {
    if (response) {
      setIsRecaptchaVerified(true);
    }
  };

  const handleRecaptchaExpired = (obj) => {
    setIsRecaptchaVerified(false);
  };

  const [specifyLanguage, setSpecifyLanguage] = useState("");
  const [companyLocalName, setCompanyLocalName] = useState("");
  const [specifyLanguageOther, setSpecifyLanguageOther] = useState("");
  const [disabledSpecifyLanguageOther, setDisabledSpecifyLanguageOther] =
    useState(true);

  const handleSpecifyLanguageChange = (event) => {
    const _specifyLanguage = event.target.value;
    setSpecifyLanguage(_specifyLanguage);
    if (_specifyLanguage === "Other") {
      setDisabledSpecifyLanguageOther(false);
    } else {
      setDisabledSpecifyLanguageOther(true);
      setSpecifyLanguageOther("");
    }
    console.log(specifyLanguage);
  };

  const handleCompanyLocalNameChange = (event) => {
    const _companyLocalName = event.target.value;
    setCompanyLocalName(_companyLocalName);
  };

  const handleSpecifyLanguageOtherChange = (event) => {
    const _specifyLanguageOther = event.target.value;
    setSpecifyLanguageOther(_specifyLanguageOther);
  };

  // const [isFormValid, setIsFormValid] = useState(true);
  // const validateSpecifyLanguage = (_companyLocalName, _specifyLanguageName) => {
  //     if (_companyLocalName) {
  //         if (_specifyLanguageName) {
  //             setRequiredFields({
  //                 ...requiredFields,
  //                 specifyLanguageName: '',
  //                 companyLocalName: ''
  //             });
  //             setIsFormValid(true);
  //         } else {
  //             setRequiredFields({
  //                 ...requiredFields,
  //                 specifyLanguageName: 'Please enter Specify Language of Company Name',
  //                 companyLocalName: ''
  //             });
  //             setIsFormValid(false);
  //         }
  //     } else {
  //         if (_specifyLanguageName) {
  //             setRequiredFields({
  //                 ...requiredFields,
  //                 specifyLanguageName: '',
  //                 companyLocalName: 'Please specify the Other Language'
  //             });
  //             setIsFormValid(false);
  //         } else {
  //             setRequiredFields({
  //                 ...requiredFields,
  //                 specifyLanguageName: '',
  //                 companyLocalName: ''
  //             });
  //             setIsFormValid(true);
  //         }
  //     }
  // }
  const [selectedCountry, setSelectedCountry] = useState("");
  const [taxNumber, setTaxNumber] = useState("");

  const handleCountryChange = (e) => {
    const countryText = e.currentTarget?.querySelector(
      '[value="' + e.currentTarget.value + '"]'
    )?.textContent;
    const countryCode = countryText?.substring(0, 2);
    setSelectedCountry(countryCode);

    // Reset tax number validation when country changes
    setRequiredFields({
      ...requiredFields,
      isInvalidTaxNumber: false,
    });
    setRequiredFields({
      ...requiredFields,
      isBlankCountryCode: e.target.value ? false : true,
    });
    setTaxNumber("");
  };

  const handleTaxNumberChange = (e) => {
    const taxNumberValue = e.currentTarget.value;

    // Validate tax number based on selected country
    const isValidTaxNumber = validateTaxNumber(taxNumberValue, selectedCountry);

    setTaxNumber(taxNumberValue);
    setRequiredFields({
      ...requiredFields,
      isInvalidTaxNumber: !isValidTaxNumber,
    });
  };

  const validateTaxNumber = (taxNumber, country) => {
    switch (country) {
      case "BE": //Belgium
        return /^[^\s]{0,10}$/.test(taxNumber);
      case "IT": //Italy
        return /^[^\s]{0,16}$/.test(taxNumber);
      case "MX": //Mexico
        return /^[^\s]{0,13}$/.test(taxNumber);
      case "VE": //Venezuela
        return /^[^\s]{0,12}$/.test(taxNumber);
      case "CH": //Switzerland
        return /^[0-9]{0,7}$/.test(taxNumber);
      case "DK": //Denmark
        return /^[0-9]{0,7}$/.test(taxNumber);
      case "PE": //Peru
        return /^[0-9]{0,11}$/.test(taxNumber);
      case "PH": //Philippines
        return /^[0-9]{0,13}$/.test(taxNumber);
      case "RU": //Russian Fed.
        return /^[0-9]{0,12}$/.test(taxNumber);
      case "ES": //Brazil
        return /^[^\s]{9}$/.test(taxNumber);
      case "BR": //Brazil
        return /^[0-9]{14}$/.test(taxNumber);
      case "KR": //South Korea
        return /^[0-9]{13}$/.test(taxNumber);
      case "PT": //Portugal
        return /^[0-9]{9}$/.test(taxNumber);
      case "TH": // Thailand
        return /^[0-9]{13}$/.test(taxNumber);
      case "ZA": //South Africa
        return /^[0-9]{10}$/.test(taxNumber);
      case "AU": //Australia
        return /^.{0,16}$/.test(taxNumber);
      case "CA": //Canada
        return /^.{0,10}$/.test(taxNumber);
      case "CL": //Chile
        return /^.{0,11}$/.test(taxNumber);
      case "CN": //China
        return /^.{0,15}$/.test(taxNumber);
      case "CZ": //Czech Republic
        return /^.{0,14}$/.test(taxNumber);
      case "SG": //Singapore
        return /^.{0,16}$/.test(taxNumber);
      case "SK": //Slovakia
        return /^.{0,14}$/.test(taxNumber);
      case "US": //USA
        return /^.{0,11}$/.test(taxNumber);
      case "CO": //Colombia
        return /^[0-9]{0,16}$/.test(taxNumber);
      case "PL": //Poland
        return /^.{13}$/.test(taxNumber);
      // case 'VN': // Vietnam
      //     return /^[0-9]{10,13}$/.test(taxNumber);
      // case 'ID': // Indonesia
      //     return /^[0-9]{16}$/.test(taxNumber);
      // case 'IN': // India
      //     return /^[0-9]{2}[A-Z]{1}[ABCFGHJLPTK]{3}[A-Z]{1}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1}$/.test(taxNumber);
      // case 'HK': //Hong Kong
      //     return /^[0-9]{16}$/.test(taxNumber);
      // case 'AE': //United Arab Emirates
      //     return /^[0-9]{15}$/.test(taxNumber);
      // case 'MY': //Malaysia
      //     return /^[0-9]{10}$/.test(taxNumber);
      // case 'JP': //Japan
      //     return /^[0-9]{13}$/.test(taxNumber);
      default:
        return true; // No validation for other countries
    }
  };

  const getMaxLengthForCountry = (country) => {
    const countryUpper = country.toUpperCase();
    switch (countryUpper) {
      case "BE": //Belgium
        return 10;
      case "IT": //Italy
        return 16;
      case "MX": //Mexico
        return 13;
      case "VE": //Venezuela
        return 12;
      case "CH": //Switzerland
        return 7;
      case "DK": //Denmark
        return 7;
      case "PE": //Peru
        return 11;
      case "PH": //Philippines
        return 13;
      case "RU": //Russian Fed.
        return 12;
      case "ES": //Spain
        return 9;
      case "BR": //Brazil
        return 14;
      case "KR": //South Korea
        return 13;
      case "PT": //Portugal
        return 9;
      case "TH": //Thailand
        return 13;
      case "ZA": //South Africa
        return 10;
      case "AU": //Australia
        return 16;
      case "CA": //Canada
        return 10;
      case "CL": //Chile
        return 11;
      case "CN": //China
        return 15;
      case "CZ": //Czech Republic
        return 14;
      case "SG": //Singapore
        return 16;
      case "SK": //Slovakia
        return 14;
      case "US": //USA
        return 11;
      case "CO": //Colombia
        return 16;
      case "PL": //Poland
        return 13;
      case "VN": //Vietnam
        return 13;
      case "ID": //Indonesia
        return 16;
      case "IN": //India
        return 40;
      case "HK": //Hong Kong
        return 16;
      case "AE": //United Arab Emirates
        return 15;
      case "MY": //Malaysia
        return 10;
      case "JP": //Japan
        return 13;
      default: // Default maxLength for other countries
        return 20;
    }
  };

  return (
    <div className="PreRegisterScreen">
      {isLoading && (
        <div className="slds-spinner_container slds-is-fixed">
          <div role="status" className="slds-spinner slds-spinner_medium">
            <span className="slds-assistive-text">Loading</span>
            <div className="slds-spinner__dot-a"></div>
            <div className="slds-spinner__dot-b"></div>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="slds-large-size_8-of-12 slds-medium-size_1-of-1 slds-size_1-of-1 slds-p-around_xx-small box-absolute-center">
          {/* <img src={logo} className="logo" alt="logo" /> */}
          <h1 className="box-caption">Registration of Interests</h1>
          <div className="box">
            {/* {errorMessage && (
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Alert icon={false} severity="error">
                                    {errorMessage}
                                </Alert>
                            </div>
                        )} */}
            {alertMessage && (
              <div
                className="slds-notify slds-notify_alert slds-alert_error slds-m-bottom_xx-small"
                role="alert"
              >
                <span className="slds-assistive-text">error</span>
                <span
                  className="slds-icon_container slds-icon-utility-error slds-m-right_x-small"
                  title="Description of icon when needed"
                >
                  <svg
                    className="slds-icon slds-icon_x-small"
                    aria-hidden="true"
                  >
                    <use xlinkHref={`${symbols}#error`}></use>
                  </svg>
                </span>
                <h2>{alertMessage}</h2>
              </div>
            )}
            {isOffline && (
              <div
                className="slds-notify slds-notify_alert slds-alert_error slds-m-bottom_xx-small"
                role="alert"
              >
                <span className="slds-assistive-text">offline</span>
                <span
                  className="slds-icon_container slds-icon-utility-error slds-m-right_x-small"
                  title="Description of icon when needed"
                >
                  <svg
                    className="slds-icon slds-icon_x-small"
                    aria-hidden="true"
                  >
                    <use xlinkHref={`${symbols}#offline`}></use>
                  </svg>
                </span>
                <h2>
                  Connection error, unable to connect to server. Please try
                  again later.{" "}
                  <span
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={initializeComponent}
                  >
                    refresh
                  </span>
                </h2>
              </div>
            )}
            <Typography variant="subtitle1">
              <abbr className="slds-required">*</abbr>
              is required field
            </Typography>
            <div className="slds-grid slds-gutters slds-wrap">
              <div className="slds-col slds-size_1-of-1 slds-large-size_1-of-2 slds-var-p-bottom_large">
                <p className="caption">Company Information</p>
                <br />
                <div className="slds-form-element">
                  <div
                    className={
                      requiredFields.isBlankEnglishName
                        ? "slds-form-element slds-has-error"
                        : "slds-form-element"
                    }
                  >
                    <label
                      className="slds-form-element__label"
                      htmlFor="EnglishName"
                    >
                      <abbr className="slds-required" title="required">
                        *
                      </abbr>
                      Company Name (English)
                    </label>
                    <div className="slds-form-element__control">
                      <input
                        type="text"
                        id="EnglishName"
                        placeholder="English Name"
                        className="slds-input"
                        maxLength="70"
                        required
                        onChange={(e) =>
                          setRequiredFields({
                            ...requiredFields,
                            isBlankEnglishName: e.target.value ? false : true,
                          })
                        }
                        autoComplete="off"
                      />
                    </div>
                    {requiredFields.isBlankEnglishName && (
                      <div className="slds-form-element__help">
                        Please enter your company name
                      </div>
                    )}
                  </div>

                  <div className="slds-form-element">
                    <fieldset className="slds-form-element slds-form-element_compound">
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__row">
                          <div className="slds-size_3-of-3">
                            <div
                              className={
                                requiredFields.companyLocalName
                                  ? "slds-form-element slds-has-error"
                                  : "slds-form-element"
                              }
                            >
                              <label
                                className="slds-form-element__label"
                                htmlFor="LocalName"
                              >
                                Company Name (Other Language)
                              </label>
                              <div className="slds-form-element__control">
                                <input
                                  type="text"
                                  id="LocalName"
                                  placeholder="Other Languages"
                                  className="slds-input"
                                  maxLength="70"
                                  value={companyLocalName}
                                  onChange={handleCompanyLocalNameChange}
                                  // onBlur={(e) => {
                                  //     validateSpecifyLanguage(e.currentTarget.value, specifyLanguage)
                                  // }}
                                />
                              </div>
                              {requiredFields.companyLocalName && (
                                <div className="slds-form-element__help">
                                  Company Name (Other Languages, Please specify)
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="slds-form-element__row">
                          <div className="slds-size_1-of-3">
                            <div
                              className={
                                requiredFields.specifyLanguageName
                                  ? "slds-form-element slds-has-error"
                                  : "slds-form-element"
                              }
                            >
                              <label
                                className="slds-form-element__label"
                                htmlFor="SpecifyLanguage"
                              >
                                Specify Language
                              </label>
                              <div className="slds-form-element__control">
                                <div className="slds-select_container">
                                  <select
                                    className="slds-select"
                                    id="SpecifyLanguage"
                                    onChange={handleSpecifyLanguageChange}
                                    // onBlur={(e) => {
                                    //     validateSpecifyLanguage(companyLocalName, e.currentTarget.value)
                                    // }}
                                  >
                                    <option value="">Select Language</option>
                                    {picklistLanguage &&
                                      picklistLanguage.map((item) => (
                                        <option
                                          key={item.value}
                                          value={item.value}
                                        >
                                          {item.label}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              {requiredFields.specifyLanguageName && (
                                <div className="slds-form-element__help">
                                  Specify Language of Company Name
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="slds-size_2-of-3">
                            <div
                              className={
                                requiredFields.specifyLanguageOther
                                  ? "slds-form-element slds-has-error"
                                  : "slds-form-element"
                              }
                            >
                              <label
                                className="slds-form-element__label"
                                htmlFor="LocalName"
                              >
                                Other Language
                              </label>
                              <div className="slds-form-element__control">
                                <input
                                  type="text"
                                  id="SpecifyLanguageOther"
                                  placeholder="Other Language"
                                  className="slds-input"
                                  maxLength="120"
                                  disabled={disabledSpecifyLanguageOther}
                                  value={specifyLanguageOther}
                                  onChange={handleSpecifyLanguageOtherChange}
                                  // onBlur={(e) => {
                                  //     validateSpecifyLanguage(e.currentTarget.value, specifyLanguage)
                                  // }}
                                />
                              </div>
                              {requiredFields.specifyLanguageOther && (
                                <div className="slds-form-element__help">
                                  Other Language (Please specify)
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  {/* <div className={
                                        requiredFields.specifyLanguageName
                                            ? "slds-form-element slds-m-top_large slds-has-error"
                                            : "slds-form-element slds-m-top_large"
                                    }
                                    >
                                        <label className="slds-form-element__label" htmlFor="SpecifyLanguage">
                                            Specify Other Language of Company Name (Optional)
                                        </label>
                                        <div className="slds-form-element__control">
                                            <div className="slds-select_container">
                                                <select
                                                    className="slds-select"
                                                    id="SpecifyLanguage"
                                                    onChange={handleSpecifyLanguageChange}
                                                    onBlur={(e) => {
                                                        validateSpecifyLanguage(companyLocalName, e.currentTarget.value)
                                                    }}
                                                >
                                                    <option value="">Select Language</option>
                                                    {picklistLanguage && picklistLanguage.map((item) => (
                                                        <option key={item.value} value={item.value}>
                                                            {item.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {requiredFields.specifyLanguageName && (
                                            <div className="slds-form-element__help">
                                                Specify Language of Company Name
                                            </div>
                                        )}
                                    </div>
                                    <div className={
                                        requiredFields.companyLocalName
                                            ? "slds-form-element slds-has-error"
                                            : "slds-form-element"
                                    }
                                    >
                                        <label className="slds-form-element__label" htmlFor="LocalName">
                                            Other Language of Company Name
                                        </label>
                                        <div className="slds-form-element__control">
                                            <input
                                                type="text"
                                                id="LocalName"
                                                placeholder="Other Languages"
                                                className="slds-input"
                                                maxLength="120"
                                                value={companyLocalName}
                                                onChange={handleCompanyLocalNameChange}
                                                onBlur={(e) => {
                                                    validateSpecifyLanguage(e.currentTarget.value, specifyLanguage)
                                                }}

                                            />
                                        </div>
                                        {requiredFields.companyLocalName && (
                                            <div className="slds-form-element__help">
                                                Company Name (Other Languages, Please specify)
                                            </div>
                                        )}
                                    </div> */}

                  <div
                    className={
                      requiredFields.isBlankAliasName
                        ? "slds-form-element slds-has-error"
                        : "slds-form-element"
                    }
                  >
                    <label
                      className="slds-form-element__label"
                      htmlFor="AliasEN"
                    >
                      <abbr className="slds-required" title="required">
                        *
                      </abbr>
                      Company Name (Alias)
                    </label>
                    <div>
                      <input
                        type="text"
                        id="AliasEN"
                        placeholder="Company Name (Alias)"
                        className="slds-input"
                        maxLength="120"
                        required
                        onChange={(e) =>
                          setRequiredFields({
                            ...requiredFields,
                            isBlankAliasName: e.target.value ? false : true,
                          })
                        }
                        autoComplete="off"
                      />
                    </div>
                    {requiredFields.isBlankAliasName && (
                      <div className="slds-form-element__help">
                        Please enter your company name (alias)
                      </div>
                    )}
                  </div>
                  <div className="slds-form-element">
                    <label
                      className="slds-form-element__label"
                      htmlFor="FormerCompany"
                    >
                      Former Company Name (if any)
                    </label>
                    <div className="slds-form-element__control">
                      <input
                        type="text"
                        id="FormerCompany"
                        placeholder="Former Company Name"
                        className="slds-input"
                        maxLength="120"
                      />
                    </div>
                  </div>
                  <div
                    className={
                      requiredFields.isBlankCountryCode
                        ? "slds-form-element slds-has-error"
                        : "slds-form-element"
                    }
                  >
                    <label
                      className="slds-form-element__label"
                      htmlFor="CountryCode"
                    >
                      <abbr className="slds-required" title="required">
                        *
                      </abbr>
                      Country
                    </label>
                    <div className="slds-form-element__control">
                      <div className="slds-select_container">
                        <select
                          className="slds-select"
                          id="CountryCode"
                          required
                          onChange={handleCountryChange}
                          autoComplete="off"
                        >
                          <option value="">Select a Country</option>
                          {picklistCountryData &&
                            picklistCountryData.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    {requiredFields.isBlankCountryCode && (
                      <div className="slds-form-element__help">
                        Please enter country of your company
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      requiredFields.isInvalidTaxNumber
                        ? "slds-form-element slds-has-error"
                        : "slds-form-element"
                    }
                  >
                    <label
                      className="slds-form-element__label"
                      htmlFor="TAXNumber"
                    >
                      TAX Number
                    </label>
                    <div className="slds-form-element__icon">
                      <button className="slds-button slds-button_icon help-text">
                        <svg className="slds-button__icon" aria-hidden="true">
                          <use xlinkHref={`${symbols}#info`}></use>
                        </svg>
                        <span className="slds-assistive-text">Help</span>
                      </button>
                      <div
                        className="slds-popover slds-popover_tooltip slds-nubbin_bottom-left"
                        role="tooltip"
                        id="help"
                        style={{
                          position: "absolute",
                          top: "-160px",
                          left: "-15px",
                          width: "250px",
                        }}
                      >
                        <div className="slds-popover__body">
                          <p>
                            N = number <br /> A = letter <br /> X = A, B, C, F,
                            G, H, L, J, P, T or <br /> Y = letter or number{" "}
                            <br /> Z = Z
                          </p>
                          <p className="slds-var-p-top_small">
                            Thailand: NNNNNNNNNNNNN <br /> Vietnam: NNNNNNNNNN <br />{" "}
                            Indonesia: YYYYYYYYYYYYYYYYYYYY <br /> Singapore:
                            NNNNNNNNNA
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="slds-form-element__control">
                      <input
                        type="text"
                        id="TAXNumber"
                        placeholder="TAX Number"
                        className="slds-input"
                        maxLength={getMaxLengthForCountry(selectedCountry)}
                        value={taxNumber}
                        onChange={handleTaxNumberChange}
                      />
                    </div>
                    {requiredFields.isInvalidTaxNumber && (
                      <div className="slds-form-element__help">
                        Please enter a valid TAX Number for the selected
                        country.
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <p className="caption">Contact Person Information</p>
                <br />
                <div className="slds-form-element">
                  <fieldset className="slds-form-element slds-form-element_compound">
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__row">
                        <div className="slds-size_1-of-5">
                          <div className="slds-form-element">
                            <label
                              className="slds-form-element__label"
                              htmlFor="Title"
                            >
                              Title
                            </label>
                            <div className="slds-form-element__control">
                              <div className="slds-select_container">
                                <select className="slds-select" id="Title">
                                  <option value="">Title</option>
                                  {picklistTitle &&
                                    picklistTitle.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="slds-size_2-of-5">
                          <div
                            className={
                              requiredFields.isBlankFirstName
                                ? "slds-form-element slds-has-error"
                                : "slds-form-element"
                            }
                          >
                            <label
                              className="slds-form-element__label"
                              htmlFor="FirstName"
                            >
                              <abbr className="slds-required" title="required">
                                *
                              </abbr>
                              First Name
                            </label>
                            <div className="slds-form-element__control">
                              <input
                                type="text"
                                id="FirstName"
                                placeholder="First Name"
                                className="slds-input"
                                maxLength="40"
                                required
                                onChange={(e) =>
                                  setRequiredFields({
                                    ...requiredFields,
                                    isBlankFirstName: e.target.value
                                      ? false
                                      : true,
                                  })
                                }
                                autoComplete="off"
                              />
                            </div>
                            {requiredFields.isBlankFirstName && (
                              <div className="slds-form-element__help">
                                Please enter your first name
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="slds-size_2-of-5">
                          <div
                            className={
                              requiredFields.isBlankLastName
                                ? "slds-form-element slds-has-error"
                                : "slds-form-element"
                            }
                          >
                            <label
                              className="slds-form-element__label"
                              htmlFor="LastName"
                            >
                              <abbr className="slds-required" title="required">
                                *
                              </abbr>
                              Last Name
                            </label>
                            <div className="slds-form-element__control">
                              <input
                                type="text"
                                id="LastName"
                                placeholder="Last Name"
                                className="slds-input"
                                maxLength="80"
                                required
                                onChange={(e) =>
                                  setRequiredFields({
                                    ...requiredFields,
                                    isBlankLastName: e.target.value
                                      ? false
                                      : true,
                                  })
                                }
                                autoComplete="off"
                              />
                            </div>
                            {requiredFields.isBlankLastName && (
                              <div className="slds-form-element__help">
                                Please enter your last name
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__row">
                        <div className="slds-size_1-of-3">
                          <div
                            className={
                              requiredFields.isBlankMobileCountryCode
                                ? "slds-form-element slds-has-error"
                                : "slds-form-element"
                            }
                          >
                            <label
                              className="slds-form-element__label"
                              htmlFor="MobileCountryCode"
                            >
                              <abbr className="slds-required" title="required">
                                *
                              </abbr>
                              Country Code
                            </label>
                            <div className="slds-form-element__control">
                              <div className="slds-select_container">
                                <select
                                  className="slds-select"
                                  id="MobileCountryCode"
                                  required
                                  onChange={(e) =>
                                    setRequiredFields({
                                      ...requiredFields,
                                      isBlankMobileCountryCode: e.target.value
                                        ? false
                                        : true,
                                    })
                                  }
                                  autoComplete="off"
                                >
                                  <option value="">Select Code</option>
                                  {picklistMobileCountry &&
                                    picklistMobileCountry.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            {requiredFields.isBlankMobileCountryCode && (
                              <div className="slds-form-element__help">
                                Please enter your mobile country code
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="slds-size_2-of-3">
                          <div
                            className={
                              requiredFields.isBlankPhone
                                ? "slds-form-element slds-has-error"
                                : "slds-form-element"
                            }
                          >
                            <label
                              className="slds-form-element__label"
                              htmlFor="Phone"
                            >
                              <abbr className="slds-required" title="required">
                                *
                              </abbr>
                              Mobile
                            </label>
                            <div className="slds-form-element__control">
                              <input
                                onKeyPress={(event) => {
                                  if (!/[0-9-#]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                type="text"
                                id="Phone"
                                placeholder="Phone Number"
                                maxLength="30"
                                className="slds-input"
                                required
                                onChange={(e) =>
                                  setRequiredFields({
                                    ...requiredFields,
                                    isBlankPhone: e.target.value ? false : true,
                                  })
                                }
                                autoComplete="off"
                              />
                            </div>
                            {requiredFields.isBlankPhone && (
                              <div className="slds-form-element__help">
                                Please enter your mobile phone number
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div
                    className={
                      requiredFields.isBlankEmail ||
                      requiredFields.isInvalidEmailFormat
                        ? "slds-form-element slds-has-error"
                        : "slds-form-element"
                    }
                  >
                    <label className="slds-form-element__label" htmlFor="Email">
                      <abbr className="slds-required" title="required">
                        *
                      </abbr>
                      Email Address
                    </label>
                    <div className="slds-form-element__control">
                      <input
                        type="email"
                        id="Email"
                        placeholder="Email Address"
                        className="slds-input"
                        maxLength="241"
                        required
                        onChange={(e) => {
                          const emailPattern =
                            /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_.]+@[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-]+(?:\.[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-]+)*$/;

                          setRequiredFields({
                            ...requiredFields,
                            isBlankEmail: e.target.value ? false : true,
                            isInvalidEmailFormat: !emailPattern.test(
                              e.currentTarget.value
                            ),
                          });
                        }}
                        autoComplete="off"
                      />
                    </div>
                    {requiredFields.isBlankEmail && (
                      <div className="slds-form-element__help">
                        Please enter your email address
                      </div>
                    )}
                    {requiredFields.isInvalidEmailFormat && (
                      <div className="slds-form-element__help">
                        Please correct your email address
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <hrVertical className="hrVertical"> </hrVertical> */}
              <div className="slds-col slds-size_1-of-1 slds-large-size_1-of-2">
                <p className="caption">Product Type Of Interest</p>
                <br />
                <fieldset
                  className={
                    requiredFields.isNotSelectedProduct
                      ? "slds-form-element slds-has-error select-product"
                      : "slds-form-element select-product"
                  }
                >
                  <div className="slds-form-element__control">
                    <div className="slds-checkbox">
                      <input
                        type="checkbox"
                        id="Customer"
                        value="Customer"
                        checked={isCheckedCustomer}
                        onChange={ChangeCheckedCustomer}
                      />
                      <label
                        className="slds-checkbox__label"
                        htmlFor="Customer"
                      >
                        <span className="slds-checkbox_faux"></span>
                        <span className="slds-form-element__label">
                          Customer
                        </span>
                      </label>
                    </div>
                    <ul>
                      <div className="slds-checkbox">
                        <input
                          type="checkbox"
                          name="Customer"
                          id="CustomerCrude"
                          value="CustomerCrude"
                          disabled={!isCheckedCustomer}
                          onChange={handleChangeSelectedProduct}
                        />
                        <label
                          className="slds-checkbox__label"
                          htmlFor="CustomerCrude"
                        >
                          <span className="slds-checkbox_faux"></span>
                          <span className="slds-form-element__label">
                            Crude
                          </span>
                        </label>
                      </div>
                      <div
                        className="slds-checkbox"
                        style={{ marginTop: "8px" }}
                      >
                        <input
                          type="checkbox"
                          name="Customer"
                          id="PetroleumProducts"
                          value="PetroleumProducts"
                          disabled={!isCheckedCustomer}
                          onChange={handleChangeSelectedProduct}
                        />
                        <label
                          className="slds-checkbox__label"
                          htmlFor="PetroleumProducts"
                        >
                          <span className="slds-checkbox_faux"></span>
                          <span className="slds-form-element__label">
                            Petroleum Products (UG95, UG91, etc.)
                          </span>
                        </label>
                      </div>
                      <div
                        className="slds-checkbox"
                        style={{ marginTop: "8px" }}
                      >
                        <input
                          type="checkbox"
                          name="Customer"
                          id="PetrochemicalProducts"
                          value="PetrochemicalProducts"
                          disabled={!isCheckedCustomer}
                          onChange={handleChangeSelectedProduct}
                        />
                        <label
                          className="slds-checkbox__label"
                          htmlFor="PetrochemicalProducts"
                        >
                          <span className="slds-checkbox_faux"></span>
                          <span className="slds-form-element__label">
                            Petrochemical Products (Paraxylene etc.)
                          </span>
                        </label>
                      </div>
                      <fieldset
                        className="slds-form-element slds-form-element_compound"
                        style={{ marginTop: "5px" }}
                      >
                        <div
                          className="slds-form-element__row"
                          style={{ alignItems: "center" }}
                        >
                          <div className="slds-size_1-of-2 slds-large-size_5-of-12">
                            <div className="slds-form-element">
                              <div className="slds-checkbox">
                                <input
                                  type="checkbox"
                                  name="Customer"
                                  id="LubeBaseProducts"
                                  value="LubeBaseProducts"
                                  onChange={handleChangeLubeBase}
                                  disabled={!isCheckedCustomer}
                                />
                                <label
                                  className="slds-checkbox__label"
                                  htmlFor="LubeBaseProducts"
                                >
                                  <span className="slds-checkbox_faux"></span>
                                  <span className="slds-form-element__label">
                                    Lube Base Products
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="slds-size_1-of-2 slds-large-size_7-of-12">
                            <div
                              className={
                                requiredFields.isBlankLubeBaseProduct
                                  ? "slds-form-element slds-has-error"
                                  : "slds-form-element"
                              }
                            >
                              <div className="slds-form-element__control">
                                <div>
                                  <input
                                    type="text"
                                    id="Products"
                                    placeholder="Please specify products"
                                    className="slds-input"
                                    disabled={!isSubLubeBase}
                                    style={{
                                      boxShadow: "none",
                                    }}
                                    onBlur={(e) =>
                                      setRequiredFields({
                                        ...requiredFields,
                                        isBlankLubeBaseProduct:
                                          !e.currentTarget.value,
                                      })
                                    }
                                  />
                                </div>
                                {requiredFields.isBlankLubeBaseProduct && (
                                  <div className="slds-form-element__help">
                                    Please specify products
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <fieldset
                        className="slds-form-element slds-form-element_compound"
                        style={{ marginTop: "1px" }}
                      >
                        <div
                          className="slds-form-element__row"
                          style={{ alignItems: "center" }}
                        >
                          <div className="slds-size_1-of-2 slds-large-size_5-of-12">
                            <div className="slds-form-element">
                              <div className="slds-checkbox">
                                <input
                                  type="checkbox"
                                  name="Customer"
                                  id="LaBixProducts"
                                  value="LaBixProducts"
                                  onChange={handleChangeLABIX}
                                  disabled={!isCheckedCustomer}
                                />
                                <label
                                  className="slds-checkbox__label"
                                  htmlFor="LaBixProducts"
                                >
                                  <span className="slds-checkbox_faux"></span>
                                  <span className="slds-form-element__label">
                                    LABIX Products
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="slds-size_1-of-2 slds-large-size_7-of-12">
                            <div
                              className={
                                requiredFields.isBlankLABIXProduct
                                  ? "slds-form-element slds-has-error"
                                  : "slds-form-element"
                              }
                            >
                              <div className="slds-form-element__control">
                                <div>
                                  <input
                                    type="text"
                                    id="InterestedLABIXProducts"
                                    placeholder="Please specify products"
                                    className="slds-input"
                                    disabled={!isSubLabix}
                                    style={{
                                      boxShadow: "none",
                                    }}
                                    onBlur={(e) =>
                                      setRequiredFields({
                                        ...requiredFields,
                                        isBlankLABIXProduct:
                                          !e.currentTarget.value,
                                      })
                                    }
                                  />
                                </div>
                                {requiredFields.isBlankLABIXProduct && (
                                  <div className="slds-form-element__help">
                                    Please specify products
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>

                      <fieldset
                        className="slds-form-element slds-form-element_compound"
                        style={{ marginTop: "1px" }}
                      >
                        <div
                          className="slds-form-element__row"
                          style={{ alignItems: "center" }}
                        >
                          <div className="slds-size_1-of-2 slds-large-size_5-of-12">
                            <div className="slds-form-element">
                              <div className="slds-checkbox">
                                <input
                                  type="checkbox"
                                  name="Customer"
                                  id="OtherProducts"
                                  value="OtherProducts"
                                  onChange={handleChangeOtherCustomer}
                                  disabled={!isCheckedCustomer}
                                />
                                <label
                                  className="slds-checkbox__label"
                                  htmlFor="OtherProducts"
                                >
                                  <span className="slds-checkbox_faux"></span>
                                  <span className="slds-form-element__label">
                                    Other Products
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="slds-size_1-of-2 slds-large-size_7-of-12">
                            <div
                              className={
                                requiredFields.isBlankOtherProductCustomer
                                  ? "slds-form-element slds-has-error"
                                  : "slds-form-element"
                              }
                            >
                              <div className="slds-form-element__control">
                                <div>
                                  <input
                                    type="text"
                                    id="InterestedOtherProducts"
                                    placeholder="Please specify products"
                                    className="slds-input"
                                    disabled={!isSubOther}
                                    style={{
                                      boxShadow: "none",
                                    }}
                                    onBlur={(e) =>
                                      setRequiredFields({
                                        ...requiredFields,
                                        isBlankOtherProductCustomer:
                                          !e.currentTarget.value,
                                      })
                                    }
                                  />
                                </div>
                                {requiredFields.isBlankOtherProductCustomer && (
                                  <div className="slds-form-element__help">
                                    Please specify products
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </ul>
                  </div>
                  {/* </fieldset> */}
                  <br />
                  {/* <fieldset className={
                                    requiredFields.isNotSelectedProduct
                                        ? "slds-form-element slds-has-error"
                                        : "slds-form-element"
                                    }
                                > */}
                  <div className="slds-form-element__control">
                    <div className="slds-checkbox">
                      <input
                        type="checkbox"
                        id="Supplier"
                        value="Supplier"
                        onChange={ChangeCheckedSupplier}
                      />
                      <label
                        className="slds-checkbox__label"
                        htmlFor="Supplier"
                      >
                        <span className="slds-checkbox_faux"></span>
                        <span className="slds-form-element__label">
                          Supplier
                        </span>
                      </label>
                    </div>
                    <ul>
                      <div className="slds-checkbox">
                        <input
                          type="checkbox"
                          name="Supplier"
                          id="SupplierCrude"
                          value="SupplierCrude"
                          disabled={!isCheckedSupplier}
                          onChange={handleChangeSelectedProduct}
                        />
                        <label
                          className="slds-checkbox__label"
                          htmlFor="SupplierCrude"
                        >
                          <span className="slds-checkbox_faux"></span>
                          <span className="slds-form-element__label">
                            Crude
                          </span>
                        </label>
                      </div>
                      <div className="slds-checkbox margin-top">
                        <input
                          type="checkbox"
                          name="Supplier"
                          id="Ethanol"
                          value="Ethanol"
                          disabled={!isCheckedSupplier}
                          onChange={handleChangeSelectedProduct}
                        />
                        <label
                          className="slds-checkbox__label"
                          htmlFor="Ethanol"
                        >
                          <span className="slds-checkbox_faux"></span>
                          <span className="slds-form-element__label">
                            B100/Ethanol
                          </span>
                        </label>
                      </div>
                      <fieldset
                        className="slds-form-element slds-form-element_compound"
                        style={{ marginTop: "5px" }}
                      >
                        <div
                          className="slds-form-element__row"
                          style={{ alignItems: "center" }}
                        >
                          <div className="slds-size_1-of-2 slds-large-size_5-of-12">
                            <div className="slds-form-element">
                              <div className="slds-checkbox">
                                <input
                                  type="checkbox"
                                  name="Supplier"
                                  id="Petroleum"
                                  value="Petroleum"
                                  onChange={handleChangePetroleum}
                                  disabled={!isCheckedSupplier}
                                />
                                <label
                                  className="slds-checkbox__label"
                                  htmlFor="Petroleum"
                                >
                                  <span className="slds-checkbox_faux"></span>
                                  <span className="slds-form-element__label">
                                    Petroleum and Component
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="slds-size_1-of-2 slds-large-size_7-of-12">
                            <div
                              className={
                                requiredFields.isBlankPetroleumAndComponent
                                  ? "slds-form-element slds-has-error"
                                  : "slds-form-element"
                              }
                            >
                              <div className="slds-form-element__control">
                                <div>
                                  <input
                                    type="text"
                                    id="InterestedPetroleumProducts"
                                    placeholder="Please specify products"
                                    className="slds-input"
                                    disabled={!isSubPetroleum}
                                    style={{
                                      boxShadow: "none",
                                    }}
                                    onBlur={(e) =>
                                      setRequiredFields({
                                        ...requiredFields,
                                        isBlankPetroleumAndComponent:
                                          !e.currentTarget.value,
                                      })
                                    }
                                  />
                                </div>
                                {requiredFields.isBlankPetroleumAndComponent && (
                                  <div className="slds-form-element__help">
                                    Please specify products
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      {/* <div className="slds-checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="Supplier"
                                                    id="LongResidue"
                                                    value="LongResidue"
                                                    disabled={!isCheckedSupplier}
                                                    onChange={handleChangeSelectedProduct}
                                                />
                                                <label className="slds-checkbox__label" htmlFor="LongResidue">
                                                    <span className="slds-checkbox_faux"></span>
                                                    <span className="slds-form-element__label">Long Residue</span>
                                                </label>
                                            </div> */}
                      <div
                        className="slds-checkbox"
                        style={{ marginTop: "5px" }}
                      >
                        <input
                          type="checkbox"
                          name="Supplier"
                          id="NormalParaffin"
                          value="NormalParaffin"
                          disabled={!isCheckedSupplier}
                          onChange={handleChangeSelectedProduct}
                        />
                        <label
                          className="slds-checkbox__label"
                          htmlFor="NormalParaffin"
                        >
                          <span className="slds-checkbox_faux"></span>
                          <span className="slds-form-element__label">
                            Normal Paraffin
                          </span>
                        </label>
                      </div>
                      <fieldset
                        className="slds-form-element slds-form-element_compound"
                        style={{ marginTop: "5px" }}
                      >
                        <div
                          className="slds-form-element__row"
                          style={{ alignItems: "center" }}
                        >
                          <div className="slds-size_1-of-2 slds-large-size_5-of-12">
                            <div className="slds-form-element">
                              <div className="slds-checkbox">
                                <input
                                  type="checkbox"
                                  name="Supplier"
                                  id="OtherProductsSupplier"
                                  value="OtherProductsSupplier"
                                  onChange={handleChangeOtherSupplier}
                                  disabled={!isCheckedSupplier}
                                />
                                <label
                                  className="slds-checkbox__label"
                                  htmlFor="OtherProductsSupplier"
                                >
                                  <span className="slds-checkbox_faux"></span>
                                  <span className="slds-form-element__label">
                                    Other Products
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="slds-size_1-of-2 slds-large-size_7-of-12">
                            <div
                              className={
                                requiredFields.isBlankOtherProductSupplier
                                  ? "slds-form-element slds-has-error"
                                  : "slds-form-element"
                              }
                            >
                              <div className="slds-form-element__control">
                                <div>
                                  <input
                                    type="text"
                                    id="InterestedOtherProductsSupplier"
                                    placeholder="Please specify products"
                                    className="slds-input"
                                    disabled={!isSubOtherSupplier}
                                    style={{
                                      boxShadow: "none",
                                    }}
                                    onBlur={(e) =>
                                      setRequiredFields({
                                        ...requiredFields,
                                        isBlankOtherProductSupplier:
                                          !e.currentTarget.value,
                                      })
                                    }
                                  />
                                </div>
                                {requiredFields.isBlankOtherProductSupplier && (
                                  <div className="slds-form-element__help">
                                    Please specify products
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </ul>
                  </div>
                </fieldset>
                <div
                  className={
                    requiredFields.isNotSelectedProduct
                      ? "slds-form-element slds-has-error"
                      : "slds-form-element"
                  }
                >
                  {requiredFields.isNotSelectedProduct && (
                    <div className="slds-form-element__help">
                      Please select interested product type as a customer or a
                      supplier
                    </div>
                  )}
                </div>
              </div>
            </div>
            <br />
            <div className="slds-align_absolute-center">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_API_KEY_RECAPTCHA}
                size="normal"
                //onChange={(val) => setcapVal(val)}
                onChange={handleRecaptchaVerify}
                onExpired={handleRecaptchaExpired}
              />
            </div>
            <br />
            <div>
              {/* <button disabled={!capVal} className="slds-button slds-button_brand" style={{ width: '150px' }} type="submit" id="btn_submit" onClick={savepreregister}>Submit</button> */}
              {/* <button
                    disabled={
                        !isRecaptchaVerified
                        || requiredFields.isBlankEnglishName
                        || requiredFields.isBlankEnglishName === undefined
                        || !isFormValid
                        || requiredFields.isBlankCountryCode
                        || requiredFields.isBlankCountryCode === undefined
                        || requiredFields.isBlankFirstName
                        || requiredFields.isBlankFirstName === undefined
                        || requiredFields.isBlankLastName
                        || requiredFields.isBlankLastName === undefined
                        || requiredFields.isBlankMobileCountryCode
                        || requiredFields.isBlankMobileCountryCode === undefined
                        || requiredFields.isBlankPhone
                        || requiredFields.isBlankPhone === undefined
                        || requiredFields.isBlankEmail
                        || requiredFields.isBlankEmail === undefined
                        || requiredFields.isNotSelectedProduct
                        || requiredFields.isNotSelectedProduct === undefined
                        || requiredFields.isBlankLubeBaseProduct
                        || requiredFields.isBlankLABIXProduct
                        || requiredFields.isBlankOtherProductCustomer
                        || requiredFields.isBlankPetroleumAndComponent
                        || requiredFields.isBlankOtherProductSupplier
                    }
                    className="slds-button slds-button_brand ctr-button_primary slds-size_1-of-1"
                    style={{backgroundColor: !isRecaptchaVerified
                        || requiredFields.isBlankEnglishName
                        || requiredFields.isBlankEnglishName === undefined
                        || !isFormValid
                        || requiredFields.isBlankCountryCode
                        || requiredFields.isBlankCountryCode === undefined
                        || requiredFields.isBlankFirstName
                        || requiredFields.isBlankFirstName === undefined
                        || requiredFields.isBlankLastName
                        || requiredFields.isBlankLastName === undefined
                        || requiredFields.isBlankMobileCountryCode
                        || requiredFields.isBlankMobileCountryCode === undefined
                        || requiredFields.isBlankPhone
                        || requiredFields.isBlankPhone === undefined
                        || requiredFields.isBlankEmail
                        || requiredFields.isBlankEmail === undefined
                        || requiredFields.isNotSelectedProduct
                        || requiredFields.isNotSelectedProduct === undefined
                        || requiredFields.isBlankLubeBaseProduct
                        || requiredFields.isBlankLABIXProduct
                        || requiredFields.isBlankOtherProductCustomer
                        || requiredFields.isBlankPetroleumAndComponent
                        || requiredFields.isBlankOtherProductSupplier ? "" : "#c20acc"}}
                    type="button"
                    onClick={openModalWarning}
                    id="btn_submit">Submit
                </button> */}
              <button
                disabled={
                  !isRecaptchaVerified ||
                  requiredFields.isBlankEnglishName ||
                  requiredFields.isBlankEnglishName === undefined ||
                  // !isFormValid ||
                  requiredFields.isBlankCountryCode ||
                  requiredFields.isBlankCountryCode === undefined ||
                  requiredFields.isBlankFirstName ||
                  requiredFields.isBlankFirstName === undefined ||
                  requiredFields.isBlankLastName ||
                  requiredFields.isBlankLastName === undefined ||
                  requiredFields.isBlankMobileCountryCode ||
                  requiredFields.isBlankMobileCountryCode === undefined ||
                  requiredFields.isBlankPhone ||
                  requiredFields.isBlankPhone === undefined ||
                  requiredFields.isBlankEmail ||
                  requiredFields.isBlankEmail === undefined ||
                  requiredFields.isNotSelectedProduct ||
                  requiredFields.isNotSelectedProduct === undefined ||
                  requiredFields.isBlankLubeBaseProduct ||
                  requiredFields.isBlankLABIXProduct ||
                  requiredFields.isBlankOtherProductCustomer ||
                  requiredFields.isBlankPetroleumAndComponent ||
                  requiredFields.isBlankOtherProductSupplier
                }
                className="slds-button slds-button_brand ctr-button_primary slds-size_1-of-1"
                style={{
                  backgroundColor:
                    !isRecaptchaVerified ||
                    requiredFields.isBlankEnglishName ||
                    requiredFields.isBlankEnglishName === undefined ||
                    // !isFormValid ||
                    requiredFields.isBlankCountryCode ||
                    requiredFields.isBlankCountryCode === undefined ||
                    requiredFields.isBlankFirstName ||
                    requiredFields.isBlankFirstName === undefined ||
                    requiredFields.isBlankLastName ||
                    requiredFields.isBlankLastName === undefined ||
                    requiredFields.isBlankMobileCountryCode ||
                    requiredFields.isBlankMobileCountryCode === undefined ||
                    requiredFields.isBlankPhone ||
                    requiredFields.isBlankPhone === undefined ||
                    requiredFields.isBlankEmail ||
                    requiredFields.isBlankEmail === undefined ||
                    requiredFields.isNotSelectedProduct ||
                    requiredFields.isNotSelectedProduct === undefined ||
                    requiredFields.isBlankLubeBaseProduct ||
                    requiredFields.isBlankLABIXProduct ||
                    requiredFields.isBlankOtherProductCustomer ||
                    requiredFields.isBlankPetroleumAndComponent ||
                    requiredFields.isBlankOtherProductSupplier
                      ? ""
                      : "#c20acc",
                }}
                type="button"
                onClick={openModalWarning}
                id="btn_submit"
              >
                Submit
              </button>
              <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <form>
                  <div
                    className="slds-modal__content slds-p-around_medium"
                    id="modal-content-id-1"
                  >
                    <img
                      src={logomail}
                      className="PreRegisterScreen logomail"
                      alt="logomail"
                      style={{ width: "100px" }}
                    />
                    <p className="PreRegisterScreen buttoncenter">
                      <b>Thank you for signing up.</b>
                    </p>
                    <br />
                    <p className="PreRegisterScreen buttoncenter">
                      Please check your inbox,
                      <br />
                      we've emailed you a confirmation link.
                    </p>
                  </div>
                  <br />
                  <div className="PreRegisterScreen buttoncenter">
                    <button
                      className="slds-button slds-button_brand"
                      onClick={closeModal}
                    >
                      OK
                    </button>
                  </div>
                </form>
              </Modal>
              <Modal
                ariaHideApp={false}
                isOpen={modalWarningIsOpen}
                onRequestClose={closeModalWarning}
                style={customStylesWarning}
              >
                <form>
                  <div
                    className="slds-modal__content slds-p-around_medium"
                    id="modal-content-id-1"
                  >
                    <img
                      src={warning}
                      className="PreRegisterScreen logomail"
                      alt="Warning"
                      style={{ width: "100px" }}
                    />
                    <p className="PreRegisterScreen buttoncenter">
                      <b>Confirm your registration email address is correct.</b>
                    </p>
                    <br />
                    <p className="PreRegisterScreen buttoncenter display-email">
                      <b>{email}</b>
                    </p>
                    <br />
                    <p className="PreRegisterScreen buttoncenter">
                      Kindly review and verify your registration email address
                      <br />
                      to ensure accuracy.
                    </p>
                  </div>
                  <br />
                  <div className="PreRegisterScreen buttoncenter">
                    <button
                      className="slds-button slds-button_outline-brand warning"
                      onClick={closeModalWarning}
                    >
                      Edit
                    </button>
                    <button
                      className="slds-button slds-button_brand"
                      onClick={handleSubmit}
                    >
                      OK
                    </button>
                  </div>
                </form>
              </Modal>
            </div>
          </div>
          <br />
          <div className="box-noborder">
            <Link
              className="
                                slds-button
                                slds-button_outline-brand
                                ctr-button_primary-outline
                                slds-size_1-of-1
                            "
              to="/"
            >
              Return to Log In
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}
